<template>
  <div class="contentWrap">
    <!-- dialog -->
    <van-dialog
      v-model="confirm2Dialog"
      title="再次确认"
      show-cancel-button
      :before-close="confirm2Order"
    >
      <van-cell-group>
        <van-cell title="输入金额" :value="orderForm.check_amount" />
        <van-cell
          v-if="
            orderForm.check_name &&
            !(
              $store.state.selfData.m_id == 60007 &&
              currentOrder.bank_name == '支付宝'
            ) &&
            !(
              (currentOrder.bank_name == '支付宝' ||
                currentOrder.bank_name == '支付宝红包') &&
              String($store.state.selfData.ds_alipay_check) == '1'
            )
          "
          :title="
            currentOrder.bank_name == '抖音红包' ? '输入口令码' : '输入姓名'
          "
          :value="orderForm.check_name"
        />
        <van-cell title="订单金额" :value="currentOrder.amount" />
        <van-cell title="收款银行" :value="currentOrder.bank_name" />
        <van-cell title="收款卡号" :value="currentOrder.bank_no" />
        <van-cell title="收款人姓名" :value="currentOrder.bank_owner" />
      </van-cell-group>
    </van-dialog>

    <van-popup v-model="errorSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="['金额错误', '实名不符合']"
        @confirm="onConfirm"
        @cancel="errorSelect = false"
      />
    </van-popup>
    <van-dialog
      :before-close="setErrorBefore"
      v-model="orderErrorModal"
      show-cancel-button
      title="订单错误"
    >
      <van-form>
        <van-field
          readonly
          clickable
          name="picker"
          :value="errorTypeCh"
          label="原因"
          placeholder="选择原因"
          @click="errorSelect = true"
        />
        <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader
              :before-read="beforeUpload"
              accept="image/*,video/*"
              v-model="files"
              :max-count="3"
            />
          </template>
        </van-field>
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="setTimeOutBefore"
      v-model="orderTimeoutModal"
      show-cancel-button
      title="超时未收到款项"
    >
      <van-form>
        <van-field name="uploader" label="文件上传">
          <template #input>
            <van-uploader
              :before-read="beforeUpload"
              accept="image/*,video/*"
              v-model="timeoutFiles"
              :max-count="3"
            />
          </template>
        </van-field>

        <van-field
          v-model="note"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="setOrderBefore"
      v-model="setOrderModal"
      show-cancel-button
      title="确认提交"
    >
      <p class="dialogInfo" v-if="currentOrder.bank_name == '抖音红包'">
        请务必确认已收取款项才可进行确认，否则恐造成资金损失
      </p>
      <p
        class="dialogInfo"
        v-else-if="
          currentOrder.bank_name == '支付宝' &&
          $store.state.selfData.ds_alipay_check == 1
        "
      >
        请再次核实收到的款项金额,若正确无误,请复制填入回单上的正确金额点选确认收款
      </p>
      <p class="dialogInfo" v-else>
        请再次核实收到的款项金额<br />若正确无误,请复制填入回单上的打款人姓名<br />及正确金额后点选确认收款
      </p>
      <van-form>
        <van-field
          v-if="
            !(
              noRealNameList.includes($store.state.selfData.m_id) &&
              (currentOrder.bank_name == '支付宝' ||
                currentOrder.bank_name == '数字人民币' ||
                currentOrder.bank_name == '微信')
            ) &&
            !(
              (currentOrder.bank_name == '支付宝' ||
                currentOrder.bank_name == '支付宝红包') &&
              String($store.state.selfData.ds_alipay_check) == '1'
            )
          "
          v-model="orderForm.check_name"
          :name="currentOrder.bank_name == '抖音红包' ? '口令码' : '打款人姓名'"
          :label="
            currentOrder.bank_name == '抖音红包' ? '口令码' : '打款人姓名'
          "
          :placeholder="
            currentOrder.bank_name == '抖音红包' ? '口令码' : '打款人姓名'
          "
          :rules="[
            {
              required: true,
              message:
                '请填写' + currentOrder.bank_name == '抖音红包'
                  ? '口令码'
                  : '打款人姓名',
            },
          ]"
        />
        <van-field
          v-model="orderForm.check_amount"
          name="款项金额"
          label="款项金额"
          placeholder="款项金额"
          :rules="[{ required: true, message: '请填写款项金额' }]"
        />
        <van-field
          v-if="
            ($store.state.selfData.m_id == 84541 ||
              $store.state.selfData.m_id == 60002) &&
            (currentOrder.bank_name == '支付宝' ||
              currentOrder.bank_name == '微信')
          "
          v-model="orderForm.date"
          name="时间"
          label="时间"
          placeholder="时间"
          type="number"
          :rules="[{ required: true, message: '请填写时间' }]"
        />
        <p
          style="font-size: 12px; margin-left: 15px; color: red"
          v-if="
            ($store.state.selfData.m_id == 84541 ||
              $store.state.selfData.m_id == 60002) &&
            (currentOrder.bank_name == '支付宝' ||
              currentOrder.bank_name == '微信')
          "
        >
          订单日期2024-03-25 09:15:01 请填写0915
        </p>
      </van-form>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky style="width: 100vw">
      <van-nav-bar
        @click-left="$router.push(`/ordersC?getMethod=${currentOrder.status}`)"
      >
        <template #title>
          <h4>订单信息</h4>
        </template>
        <template #left>
          <van-icon name="arrow-left" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap orderContentWrap">
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell
          :title="'订单号'"
          :label="currentOrder.order_id"
          v-clipboard:copy="
            $returnBuySel('出货') + ': ' + currentOrder.order_id
          "
          v-clipboard:success="onCopy"
        >
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin: 0; margin-bottom: 10px"
        v-if="currentOrder.bank_name == '抖音红包'"
      >
        <van-cell
          :title="'口令码'"
          :label="currentOrder.bank_branch"
          v-clipboard:copy="currentOrder.bank_branch"
          v-clipboard:success="onCopy"
        >
          <template #right-icon>
            <van-button
              size="normal"
              block
              plain
              style="
                background-color: #4065f0;
                width: 45px;
                color: white;
                height: 22px;
                font-size: 12px;
                white-space: nowrap;
                border-radius: 5px;
              "
              >複製</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin: 0; margin-bottom: 10px"
        v-if="currentOrder.bank_name == '支付宝红包'"
      >
        <van-cell :label="currentOrder.bank_branch" :title="'紅包口令'">
          <template #right-icon>
            <van-button
              v-if="currentOrder.bank_branch.indexOf('***') != -1"
              @click="showBranch"
              size="normal"
              style="height: 35px"
              color="linear-gradient(to right, #ff6034, #ee0a24)"
            >
              点击查看
            </van-button>
            <div
              v-else
              v-clipboard:copy="currentOrder.bank_branch"
              v-clipboard:success="onCopy"
            >
              <i class="far fa-clone copyIcon"> </i>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin: 0; margin-bottom: 10px"
        v-if="currentOrder.merchant_guest_real_name"
      >
        <van-cell title="姓名" :value="currentOrder.merchant_guest_real_name" />
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell title="日期" :value="currentOrder.created_at" />
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell title="确认期限" :value="currentOrder.created_at" />
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell title="订单金额" :value="currentOrder.amount" />
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell title="收款银行" :value="currentOrder.bank_name" />
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin: 0; margin-bottom: 10px"
        v-if="currentOrder.bank_no"
      >
        <van-cell title="收款卡号" :value="currentOrder.bank_no" />
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell title="收款人姓名" :value="currentOrder.bank_owner" />
      </van-cell-group>
      <van-cell-group
        inset
        style="width: 100%; margin: 0; margin-bottom: 10px"
        v-if="currentOrder.real_name"
      >
        <van-cell
          title="打款人姓名"
          :value="$returnMarkName(currentOrder.real_name)"
        >
        </van-cell>
      </van-cell-group>
      <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-cell title="订单状态" :value="status[currentOrder.status]" />
      </van-cell-group>

      <!-- <van-cell-group inset style="width: 100%; margin: 0; margin-bottom: 10px">
        <van-card
          style="width: 285px"
          num="1"
          :price="currentOrder.price"
          :title="currentOrder.name"
          :thumb="currentOrder.img"
        />
      </van-cell-group> -->
    </div>
    <div class="footBtnWrap">
      <van-button
        @click="timeoutOrder"
        v-if="currentOrder.status == 0"
        style="
          font-weight: bold;
          height: 40px;
          border: none;
          color: #4065f0;
          white-space: nowrap;
          border-radius: 50px;
        "
        size="normal"
        block
        color="#D4DEFB"
      >
        <p style="font-weight: bold; color: #4065f0">超时未收到款项</p>
      </van-button>
      <div style="display: flex; margin-top: 15px">
        <van-button
          v-if="currentOrder.status == 0"
          @click="orderError"
          style="
            font-weight: bold;
            border-radius: 50px;
            height: 40px;
            border: none;
            margin-right: 5px;
          "
          size="normal"
          block
          type="danger"
          color="#FF4343"
          class="errorBtn"
          >订单错误</van-button
        >
        <van-button
          v-if="currentOrder.status == 0"
          @click="checkOrder"
          style="
            font-weight: bold;
            border-radius: 50px;
            height: 40px;
            border: none;
            margin-left: 5px;
          "
          size="normal"
          block
          color="#4065F0"
          >确认收款</van-button
        >
      </div>
      <van-button
        v-if="currentOrder.status != 0"
        style="
          font-weight: bold;
          border-radius: 50px;
          height: 40px;
          border: none;
        "
        size="normal"
        block
        plain
        type="danger"
        @click="$router.push(`/ordersC?getMethod=${currentOrder.status}`)"
        >返回</van-button
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { Toast } from "vant";
import * as imageConversion from "image-conversion";
export default {
  data() {
    return {
      confirm2Dialog: false,
      currentOrder: {},
      setOrderModal: false,
      orderForm: {},
      orderErrorModal: false,
      errorSelect: false,
      errorType: "",
      errorTypeCh: "",
      files: [],
      timeoutFiles: [],
      orderTimeoutModal: false,
      note: "",
      status: ["待付款", "成功", "逾时失效", "金额错误", "实名不符", "失败"],
      noRealNameList: [61929, 84541, 60002, 86696, 60007, 82926],
    };
  },
  methods: {
    checkDate(date) {
      var formattedCreatedAt = this.currentOrder.created_at.replace(/-/g, "/");
      var dateTime = new Date(formattedCreatedAt);

      if (isNaN(dateTime.getTime())) {
        console.error("Invalid date format.");
        return false;
      }

      var hours = dateTime.getHours();
      var minutes = dateTime.getMinutes();

      function pad(number) {
        return number < 10 ? "0" + number : number.toString();
      }

      var formattedHours = pad(hours);
      var formattedMinutes = pad(minutes);

      var timeString = formattedHours + formattedMinutes;

      console.log(timeString);
      return date == timeString;
    },
    showBranch() {
      this.$http
        .get(`/mobile/orders/${this.currentOrder.order_id}/alipayhbcode`)
        .then((res) => {
          if (res.data.code == 0) {
            this.currentOrder.bank_branch = res.data.data.bank_branch;
            this.currentOrder.merchant_guest_real_name =
              res.data.data.merchant_guest_real_name;
            this.$router.push({
              name: "orderCInfo",
              params: this.currentOrder,
              query: this.currentOrder,
            });
          }
        });
    },
    beforeUpload(files) {
      this.$store.dispatch("setLoadding", true);
      let allFile = Array.isArray(files) ? files : [files];
      return Promise.all(
        allFile.map((file) => {
          // 直接返回视频文件，不进行处理
          if (file.type.indexOf("video") !== -1) {
            return Promise.resolve(file);
          }

          // 对图片文件进行压缩处理
          return imageConversion
            .compressAccurately(file, 1000)
            .then((res) => {
              return res;
            })
            .catch(() => {
              return file; // 压缩失败时，返回原始文件
            });
        })
      )
        .then((results) => {
          // 所有文件处理完成后，再更改加载状态
          this.$store.dispatch("setLoadding", false);
          return results; // 返回处理后的文件数组
        })
        .catch((err) => {
          // 统一的错误处理
          this.$store.dispatch("setLoadding", false);
          console.error(err); // 或者其他的错误处理逻辑
        });
    },
    async setTimeOutBefore(method, done) {
      let vm = this;
      if (method == "confirm") {
        if (!this.timeoutFiles.length || !this.timeoutFiles.length) {
          Toast.fail("请传送图片");
          done(false);
          return;
        }
        done(false);
        var formData = new FormData();
        if (this.note) {
          formData.append("note", this.note);
        }

        this.timeoutFiles.forEach((e) => {
          formData.append("files[]", e.file);
        });

        vm.$http
          .post(`/mobile/orders/${vm.currentOrder.order_id}/expired`, formData)
          .then((res) => {
            if (res.data.code == 0) {
              vm.$router.push("/ordersC");
              vm.$toast.success("成功");
              done();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
    timeoutOrder() {
      this.timeoutFiles = [];
      this.note = "";
      this.orderTimeoutModal = true;
    },
    onConfirm(value) {
      value == "金额错误" ? (this.error_type = 3) : (this.error_type = 4);
      this.errorTypeCh = value;
      this.errorSelect = false;
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    orderError() {
      this.orderErrorModal = true;
    },
    async setErrorBefore(method, done) {
      if (method === "confirm") {
        if (!this.files || !this.files.length) {
          Toast.fail("请传送图片");
          done(false);
          return;
        }

        let vm = this;
        let formData = new FormData();
        if (!this.error_type) {
          Toast.fail("请选择原因");
          return;
        }
        formData.append("error_type", this.error_type);
        this.files.forEach((file) => {
          formData.append("files[]", file.file);
        });

        vm.$http
          .post(`/mobile/orders/${vm.currentOrder.order_id}/error`, formData)
          .then((res) => {
            if (res.data.code === 0) {
              vm.orderErrorModal = false;
              vm.$router.push("/ordersC");
              vm.$toast.success("完成");
            } else {
              // 可以在这里处理非成功的响应
            }
          })
          .catch((error) => {
            // 处理请求错误
            console.error("请求错误:", error);
            // 这里可以添加更多的错误处理逻辑
          });

        done(); // 根据您的逻辑调整 done() 的位置
      } else {
        done();
      }
    },
    checkOrder() {
      this.setOrderModal = true;
    },
    confirm2Order(method, done) {
      if (method == "confirm") {
        done();
        this.$http
          .post(
            `/mobile/orders/${this.currentOrder.order_id}/check`,
            this.orderForm
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$toast.success("完成");
              this.$router.push("/ordersC");
            }
          });
      } else {
        done();
      }
    },
    setOrderBefore(method, done) {
      if (method == "confirm") {
        done(false);
        if (!this.orderForm.check_amount) {
          this.$toast.fail("请填写金额");
          return;
        }
        if (
          (this.$store.state.selfData.m_id == 84541 ||
            this.$store.state.selfData.m_id == 60002) &&
          (this.currentOrder.bank_name == "支付宝" ||
            this.currentOrder.bank_name == "微信")
        ) {
          if (!this.orderForm.date) {
            this.$toast.fail("请填写时间");
            return;
          }

          if (!this.checkDate(this.orderForm.date)) {
            this.$toast.fail("请确认时间");
            return;
          }
        }
        this.confirm2Dialog = true;
      } else {
        done();
      }
    },
  },
  computed: {
    ...mapState(["orders"]),
  },
  watch: {
    orders() {
      this.orders.data.forEach((e) => {
        if (e.order_id == this.$route.params.id) [(this.currentOrder = e)];
      });
    },
  },
  created() {
    if (this.$route.params.check_amount) {
      this.currentOrder = this.$route.params;
    } else {
      this.currentOrder = this.$route.query;
    }
    // if (this.orders.data) {
    //   this.orders.data.forEach((e) => {
    //     if (e.order_id == this.$route.params.id) [(this.currentOrder = e)];
    //   });
    // } else {
    //   this.$store.dispatch("getOrder");
    // }
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  width: 100%;
  background-color: #f1f5fe;
}
.contentWrap {
  padding: 20px;
  display: flex;
  padding-top: 0;
  flex-direction: column;
  align-items: center;
  background-color: #f1f5fe;
  min-height: 100vh;
}
.footBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  box-shadow: 5px 0px 0px rgba(#000000, 0.05);
  height: 60px;
  font-size: 14pt;
}
.dialogInfo {
  padding: 0 5px;
  color: #ff4343;
  font-size: 12px;
  text-align: center;
}

.van-cell__title {
  span {
    font-weight: bold;
  }
}
.van-cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.footBtnWrap {
  width: 100%;
}
</style>
